import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import darkModeIcon from "./darkBT.gif";
import logoQWER from "./log_QWER.jpeg";
import endGif1 from "./end1.gif";
import endGif2 from "./end2.gif";
import endGif3 from "./end3.gif";
import { useTranslation } from "react-i18next";
import i18n from "../src/lang/i18n";
const extractYouTubeID = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

const formatDate = (dateObject) => {
  if (dateObject && dateObject.$date) {
    return dateObject.$date.split("T")[0];
  }
  return "";
};

const isMobile = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

const getRandomEndGif = () => {
  const gifs = [endGif1, endGif2, endGif3];
  return gifs[Math.floor(Math.random() * gifs.length)];
};

const FanCam = ({ darkMode, toggleDarkMode, language, langbt }) => {
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const loader = useRef(null);
  const [endGif, setEndGif] = useState(getRandomEndGif());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [eventList, setEventList] = useState([]);

  const itemsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("/fancamData.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.uploadDate.$date);
          const dateB = new Date(b.uploadDate.$date);
          return dateA - dateB;
        });
        setAllItems(sortedData);
        setItems(sortedData.slice(0, itemsPerPage));
      } catch (error) {
        console.error("Error loading JSON data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchEventList = async () => {
      try {
        const response = await fetch("/eventList.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEventList(data);
      } catch (error) {
        console.error("Error loading event list:", error);
      }
    };
    fetchEventList();
  }, []);

  const loadMoreItems = () => {
    const filteredItems = allItems.filter((item) => {
      const itemDate = new Date(item.uploadDate.$date);
      return (
        (!selectedEvent || (item.tag && item.tag.includes(selectedEvent))) &&
        (!selectedDate ||
          (itemDate.getFullYear() === selectedDate.getFullYear() &&
            itemDate.getMonth() === selectedDate.getMonth()))
      );
    });

    if (items.length >= filteredItems.length) return;
    setIsLoading(true);
    const nextPage = page + 1;
    const startIndex = nextPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setItems((prevItems) => [
      ...prevItems,
      ...filteredItems.slice(startIndex, endIndex),
    ]);
    setPage(nextPage);
    setIsLoading(false);
  };

  const handleScroll = () => {
    if (isLoading) return;
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= documentHeight * 0.7) {
      loadMoreItems();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, items]);

  const getYouTubeAppLink = (url) => {
    const videoId = extractYouTubeID(url);
    if (!videoId) return url;
    if (/Android/i.test(navigator.userAgent)) {
      return `intent://www.youtube.com/watch?v=${videoId}#Intent;package=com.google.android.youtube;scheme=https;end`;
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return `youtube://${videoId}`;
    }
    return url;
  };

  const handleThumbnailClick = (url) => {
    const link = isMobile() ? getYouTubeAppLink(url) : url;
    window.open(link, "_blank");
  };

  const handleEventFilter = (event) => {
    const newEvent = event === selectedEvent ? null : event;

    // setSelectedEvent(newEvent);
    setSelectedEvent(newEvent);
    const filteredItems = allItems.filter((item) => {
      return !newEvent || (item.tag && item.tag.includes(newEvent));
    });
    setItems(filteredItems.slice(0, itemsPerPage));
    setPage(0);
  };

  const handleDateFilter = () => {
    const filteredItems = allItems.filter((item) => {
      const itemDate = new Date(item.uploadDate.$date);
      return (
        !selectedDate ||
        (itemDate.getFullYear() === selectedDate.getFullYear() &&
          itemDate.getMonth() === selectedDate.getMonth())
      );
    });
    setItems(filteredItems.slice(0, itemsPerPage));
    setPage(0);
  };

  return (
    <div className={`FanCam ${darkMode ? "dark-mode" : ""}`}>
      <h1>{language("FanCam")}</h1>
      <h3>{language("dev_memo_1")}</h3>
      <button className="toggle-button" onClick={toggleDarkMode}>
        <div className="bwicon"></div>
        <span>{language("switMode")}</span>
      </button>
      <button className="lng-bt" onClick={langbt}>
        KR ↔ ENG
      </button>
      <div className="filter-container">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy/MM"
          showMonthYearPicker
          placeholderText="YYYY/MM"
        />

        <button onClick={handleDateFilter}>{language("date_SC_BT")}</button>
      </div>
      <div className="event-picker">
        {eventList.map((event, index) => (
          <div
            key={index}
            className={`event-item ${
              selectedEvent === event ? "selected" : ""
            }`}
            onClick={() => handleEventFilter(event)}
          >
            {event}
          </div>
        ))}
      </div>
      <div className="timeline">
        {items.map((item, index) => {
          const videoId = extractYouTubeID(item.url);
          const thumbnailUrl = videoId
            ? `https://img.youtube.com/vi/${videoId}/0.jpg`
            : "";
          const titleClass = item.title.length > 10 ? "scrolling-title" : "";
          return (
            <div
              className={`timeline-item ${index % 2 === 0 ? "left" : "right"}`}
              key={item._id}
            >
              <div className="circle">
                <img src={logoQWER} alt="QWER Logo" className="circle-img" />
              </div>
              <div className="content">
                {thumbnailUrl && (
                  <img
                    src={thumbnailUrl}
                    alt={item.title}
                    className="thumbnail"
                    onClick={() => handleThumbnailClick(item.url)}
                  />
                )}
                <div className="title-container">
                  <h2 className={titleClass}>{item.title}</h2>
                </div>
                <p>{formatDate(item.uploadDate)}</p>

                <a
                  href={isMobile() ? getYouTubeAppLink(item.churl) : item.churl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {language("ch")}:{item.source}
                </a>
                <p></p>
                <a
                  href={isMobile() ? getYouTubeAppLink(item.url) : item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {language("watch")}
                </a>
              </div>
            </div>
          );
        })}
        <div ref={loader} className="loader">
          {isLoading && <p>Loading...</p>}
        </div>
        <div className="end-gif">
          <a
            href="https://docs.google.com/spreadsheets/d/1iURMwU8WYtYzQn9KPxGPANn6ugUKtGFBXL2zvovzRhg/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={endGif} alt="End" />
          </a>
        </div>
        <a> 🐣건의사항및 영상추가 요청은 ⬆ 클릭</a>
      </div>
      <footer className="footer">{language("footer")}</footer>
    </div>
  );
};

export default FanCam;
